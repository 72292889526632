import React from "react"
import PropTypes from "prop-types"
import Layout from "../../components/layout"
import Helmet from "react-helmet"

import Hero from "../../components/hero--product"
import Content from "../../components/content-with-image"
import H4 from "../../components/Utility/heading4"
import CenteredCTA from "../../components/cta--centered"
import { BVLoader, BVReviews } from "@tyson-foods/react-component-library"
import Collapsable from "../../components/Collapse/Collapse"
import CTA from "../../components/cta"

export default class ProductDetailPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Plant Based Crispy Fillets</title>
          <meta
            name="description"
            content="Pick up a packet of Raised & Rooted&trade; Crispy Plant Based Fillets! Made with plant based protein, these crispy fillet are packed with flavor and nutrition."
          />
          <meta itemProp="name" content="Crispy Plant Based Fillets" />
        </Helmet>
        <Layout>
          <BVLoader
            clientName="raisedandrooted"
            siteID="main_site"
            env={process.env.GATSBY_BAZAARVOICE_ENV}
          />
          <div itemScope itemType="http://schema.org/Product">
            <Hero
              heroDirection="reverse"
              heroclassName="single"
              //headingTitleText1="Raised & Rooted™"
              headingTitleColor1="#006B2D"
              headingText1="Crispy Plant Based Fillets"
              headingColor1="#006B2D"
              bodyColor="#363636"
              bodyText="What came first? The chicken or the pea? Raised & Rooted&trade; Crispy Fillets are made with pea protein, boldly seasoned, and finished with crispy breading. We’ll never know what came first, but we do know that chicken’s got some stiff competition."
              itemColor="#006B2D"
              valueText1="9"
              unitText1="g"
              labelText1="Protein (per serving)"
              valueText2="33"
              unitText2="%"
              labelText2="Less Saturated Fat*"
              dataImg3="/leaf.svg"
              labelText3="No Artificial Preservatives"
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
              footnoteText="*Than USDA Data For White Meat Chicken Nuggets"
              footnoteColor="#006B2D"
              productID="00053400000460"
            >
              <img src="/raised-and-rooted-crispy-plant-based-fillets-708x721.png" alt="Raised And Rooted Crispy Plant Based Fillets" />
            </Hero>
            <Content
              ctaclassName="reverse"
              ctaBGColor="#97D700"
              bodyColor="#006B2D"
              imgSrc="/raised-and-rooted-crispy-plant-based-fillets-lifestyle-sandwich-708x721.png"
              altText="Raised And Rooted Crispy Plant Based Fillets Lifestyle Sandwich"
              headingText2="WHAT’S INSIDE?"
              headingColor2="#006B2D"
              blurb="Raised & Rooted™ Crispy Fillets are made with pea protein, perfectly seasoned and fully cooked. These juicy fillets are guaranteed to make you crave more."
            >
              <h4>NUTRITION</h4>
              <p>
                9 grams of plant based protein per serving. 
                They have 33% less saturated fat, 
                0% artificial preservatives and 100% chance to be the new family favorite.
              </p>
              <h4>TASTE</h4>
              <p>
                These fillets are filled with plant based protein and savory based flavors. 
                Cooked to a crisp and seasoned to satisfy any crunchy chicken craving.
              </p>
            </Content>

            <div className="nutrition">
              <div>
                <Collapsable
                  className={"nutrition__prepared"}
                  text={"Preparation Instructions"}
                  collapsed={false}
                >
                  <b>HEATING INSTRUCTIONS</b>
                  <p>
                    Keep frozen until ready to use. 
                    Appliances vary. Heating times approximate.
                  </p>
                  <b>Conventional OVEN</b>
                  <p>
                    Preheat oven to 400°F. 
                    Arrange fillets on a baking sheet about 1-inch apart. 
                    Bake for 12-14 minutes or until hot.
                  </p>
                </Collapsable>
                <br />
                <Collapsable
                  className={"nutrition__details"}
                  text={"Nutrition"}
                  collapsed={true}
                >
                  <p>4 servings per container</p>
                  <p>Serving size 1 filet (84g)</p>
                  <p>Amount Per Serving</p>
                  <hr />
                  <table>
                    <tr>
                      <td colspan="2">Calories 200</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colspan="2">% Daily Value *</td>
                    </tr>
                    <tr>
                      <td>Total Fat 11g</td>
                      <td>14%</td>
                    </tr>
                    <tr>
                      <td className="indent">Saturated Fat 2g</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Trans Fat 0g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Polyunsurated Fat 6g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Monounsurated Fat 3g
                      </td>
                    </tr>
                    <tr>
                      <td>Cholesterol 0mg</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Sodium 580mg</td>
                      <td>25%</td>
                    </tr>
                    <tr>
                      <td>Total Carbohydrate 18g</td>
                      <td>7%</td>
                    </tr>
                    <tr>
                      <td className="indent">Dietary Fiber 4g</td>
                      <td>14%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Total Sugars 1g
                      </td>
                    </tr>
                    <tr>
                      <td className="indent">Includes 0g Added Sugars</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Protein 9g</td>
                      <td>12%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>Vitamin D 0mcg</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Calcium 90mg</td>
                      <td>8%</td>
                    </tr>
                    <tr>
                      <td>Iron 1.7mg</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Potassium 380mg</td>
                      <td>8%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        *Percent daily values are based on a 2,000 calorie diet.
                        Your daily values may be higher or lower depending on
                        your calorie needs.
                      </td>
                    </tr>
                  </table>
                </Collapsable>
              </div>
              <Collapsable
                className={"nutrition__ingredients"}
                text={"Ingredients"}
                collapsed={true}
              >
                <p>
                    VEGAN FIBERS (WATER, PEA PROTEIN ISOLATE, CANOLA OIL, SODIUM ALGINATE, CITRUS FIBER), 
                    WATER, OAT FIBER, VITAL WHEAT GLUTEN, PEA PROTEIN, LAXSEED, METHYLCELLULOSE, 
                    MALTODEXTRIN, NATURAL FLAVORS, SEA SALT, SALT, ONION POWDER, GARLIC POWDER, 
                    YEAST EXTRACT, SUGAR, PALM OIL, CARROT FIBER, CITRIC ACID, SUNFLOWER OIL, 
                    ROSEMARY EXTRACT, MEDIUM CHAIN TRIGLYCERIDES, TORULA YEAST, ONION JUICE CONCENTRATE, 
                    SPICE, CANOLA OIL. BREADED WITH: WHEAT FLOUR, WATER, RICE FLOUR, 
                    MODIFIED WHEAT STARCH, MODIFIED CORN STARCH, SEA SALT, POTATO DEXTRIN, 
                    LEAVENING (SODIUM ACID PYROPHOSPHATE, SODIUM BICARBONATE), 
                    TAPIOCA DEXTRIN, GARLIC POWDER, SPICES, ONION POWDER, NATURAL FLAVORS, 
                    SALT, SUGAR, YEAST, EXTRACTIVES OF PAPRIKA, LEAVENING (CREAM OF TARTAR, 
                    SODIUM BICARBONATE), MALTODEXTRIN, DEXTROSE, YEAST EXTRACT, GUM ARABIC.
                </p>
                <H4 headingText="Allergens" />
                <ul className="allergens">
                  <li>Contains: wheat</li>
                  <li>CONTAINS BIOENGINEERED FOOD INGREDIENTS</li>
                </ul>
              </Collapsable>
            </div>
            <div id="reviews" className="reviews">
              <BVReviews productID="00053400000460" />
            </div>
            <CenteredCTA
              ctaBottomMargin="30px"
              bgImage="/paper-background.jpg"
              badgeSrc="/hover.svg"
              headingText2="Where to Buy Us"
              headingColor2="#006B2D"
              bodyColor="#006B2D"
              bodyText="No need to search specialty stores. You’ll find our full lineup of plant based products in the refrigerated and frozen aisles of select supermarkets."
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
            />
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}
